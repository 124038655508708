@font-face {
  font-family: kuldeep363;
  src: url("./assets/kuldeep.ttf");
}

:root {
  --white: #fefefe;
  --black: #292d32;
  --myPrimary: #366e9c;
  --sub-text: #a6a6a6;
  --shadow-1: 0px 4px 15px rgba(55, 113, 161, 0.15);
  --shadow-2: 0px 4px 15px rgba(55, 113, 161, 0.35);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: kuldeep363;
}

a {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  background-color: #efefef;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--myPrimary);
  border-radius: 10px;
}

/* 

font-family: 'Poppins', sans-serif;

*/

body {
  overflow-x: hidden;
}

.form-control {
  width: 100%;
  box-shadow: none;
  background-color: var(--white);
  border-radius: 10px;
}
.form-control:focus {
  box-shadow: none !important;
}
.form-group {
  margin-bottom: 0 !important;
}

.myContainer {
  width: 80%;
  margin: 0 auto;
}
.timeline {
  position: relative;
  max-width: 1200px;
}
.timeline::after {
  content: "";
  width: 6px;
  height: 95%;
  background-color: var(--myPrimary);
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  margin-top: 15px;
  margin-left: -3px;
}
/* #work_experience h1{
  font-size: 24px;
} */
@media (max-width: 991px) {
  .myContainer {
    width: 90%;
  }
  body {
    font-size: 0.8rem;
  }
  .timeline {
    padding-left: 60px;
  }
  .timeline::after {
    left: 20px;
  }
}
