
.primary-btn, .stroke-btn,.white-btn{
    padding: 10px 15px;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    text-align: center;
}
.primary-btn{
    box-shadow: var(--shadow-2);
    background: var(--myPrimary);
    color: var(--white);
    border: 1px solid var(--myPrimary);
}
.primary-btn:hover{
    background: transparent;
    color: var(--myPrimary);
}
.stroke-btn{
    color: var(--myPrimary);
    background-color: var(--white);
    border: 1px solid var(--myPrimary);
}
.white-btn{
    background: var(--white);
    color: var(--myPrimary);
    border: 1px solid var(--white);
}
.white-btn:hover{
    background: transparent;
    color: var(--white);
}
button{
    width: 100%;
    border: none;
}
button:focus{
    border: none;
    outline: none;
}