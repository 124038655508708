.navbar-contacts a {
    color: var(--white);
}
#menu-toggler{
    z-index: 20;
}
.mob-navbar-links a {
    color: var(--black);
}
.mob-navbar-links{
    font-size: 0.9rem;
}

.navbar-contacts {
    background-color: var(--myPrimary);
}

body.mob-nav-active {
    overflow: hidden;
}

.navbar-contacts .line {
    width: 1px;
    height: 15px;
    background-color: var(--white);
}

#nav-links a {
    color: var(--black);
    transition: 0.3s all ease-in-out;
}

#nav-links a,
#mob-navbar-links a {
    position: relative;
}

#nav-links a::before,
#mob-navbar-links a::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 2px;
    background-color: var(--myPrimary);
    transition: 0.3s all ease-in-out;
}

#nav-links a:hover::before,
#mob-navbar-links a:hover::before,
#nav-links a.active::before,
#mob-navbar-links a.active::before {
    width: 100%;
}

.social-icons img {
    width: 20px;
    transition: 0.3s all ease-in-out;
}

.contacts img {
    width: 18px;
}

#logo img{
    width: 50px;
}


.hamburger-menu {
    cursor: pointer;
}

.menu-bar1,
.menu-bar2,
.menu-bar3 {
    width: 1.4rem;
    height: 0.17rem;
    background-color: var(--myPrimary);
    margin: 0.3rem 0;
    transition: 0.4s;
}


/* ROTATE FIRST BAR */
.active .menu-bar1 {
    transform: rotate(-45deg);
    transform-origin: right;
}

/* FADE OUT SECOND BAR */
.active .menu-bar2 {
    opacity: 0;
}

/* ROTATE LAST BAR */
.active .menu-bar3 {
    transform: rotate(45deg);
    transform-origin: right;
}

#mob-navbar-links {
    width: 0;
    height: 100vh; 
    z-index: 19;
    background-color: var(--white);
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s all ease-in-out;
    overflow: hidden;

}

#mob-navbar-links.active {
    width: 100vw;
}

#mob-navbar-links .navbar-links {
    margin-bottom: 1rem;
}

#mobile-navbar {
    display: none;
}

.mob-navbar-contacts {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.mob-navbar-contacts a {
    color: var(--white);
}

.circle-yellow {
    position: absolute;
    width: 337px;
    height: 337px;
    background: rgba(255, 188, 0, 0.15);
    filter: blur(125px);
}

.circle-blue {
    position: absolute;
    width: 337px;
    height: 337px;
    background: rgba(92, 100, 174, 0.2);
    filter: blur(125px);
}

.downloadCV{
    background: transparent;
    outline: none;
    border: none;
}

.social-icons img:hover{
    transform: scale(1.1);
}

@media(max-width:991px) {
    .web-navbar-contacts {
        display: none;
    }

    .mob-navbar-contacts {
        display: block;
    }

    #mobile-navbar {
        display: flex;
    }

    #links {
        display: none;
    }
    
    #logo img{
        width: 50px;
    }
}